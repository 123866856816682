#Menu {
    width: 100%;
    height: 50px;
    background-color: #333;
    padding: 12px 0;
    font-family: Verdana, sans-serif;
    color: #ccc;

    input {
        float: left;
        width: 250px;
        margin: -7px 0 0 5px;
        background-color: #666;
        border: none;
        color: #ccc;

        &::placeholder {
            color: #aaa;
        }
    }
}

.site-title {
    float: left;
    font-size: 22px;
    font-weight: bold;
    margin: -5px 20px;
}

.menu-label {
    float: left;
    margin-left: 50px;
    margin-right: 7px;
}

#help-button {
    float: left;
    margin-left: 100px;
    background-color: #ccc;
    border-radius: 5px;
    border: 2px solid #666;
    padding: 4px 15px;
    text-decoration: none;
    color: #333;
    position: relative;
    top: -7px;

    &:hover {
        background-color: #bbb;
    }
}
