#Explored {
    display: inline-block;
    clear: both;
    font-family: Verdana, sans-serif;
    border-right: 2px solid #666;
    width: 290px;
    height: 750px;
    overflow-y: scroll;
    vertical-align: top;
    border-bottom: 2px solid #666;
    background-color: #333;
    color: #ccc;
}

@media (max-width: 1500px) {
    #Explored {
        height: 590px;
    }
}

#topbar {
    background-color: #555;
    padding: 5px 0 2px 15px;
}

#topbar2 {
    background-color: #555;
    padding: 0 0 10px 15px;

    input {
        width: 200px;
        font-size: 12px;
        background-color: #777;
        border: none;
        color: #eee;

        &::placeholder {
            color: #ccc;
        }
    }
}

.fill-list-button {
    float: left;
    border: 1px solid black;
    font-size: 14px;
    padding: 0 5px;
    cursor: pointer;
    background-color: #ddd;
    margin-top: 5px;
    color: black;
}
.fill-list-button:hover {
    background-color: #bbb;
}

.fill-sec-checkboxes {
    font-size: 12px;
    margin-left: 15px;
    display: inline-block;

    label {
        position: relative;
        left: 3px;
        top: -2px;
    }
}
