#Frontier {
    display: inline-block;
    clear: both;
    font-family: Verdana, sans-serif;
    width: 1220px;
    min-height: 725px;
    vertical-align: top;
    background-color: #333;
    color: #ccc;
}

@media (max-width: 1500px) {
    #Frontier {
        width: 1040px;
        min-height: 590px;
    }
}

.frontier-button {
    display: inline-block;
    border: 1px solid black;
    font-size: 14px;
    padding: 0 5px;
    cursor: pointer;
    background-color: #ddd;
    color: black;
}
.frontier-button:hover {
    background-color: #bbb;
}

#data-source-label {
    margin-right: 7px;
}

#data-source-select {
    margin-right: 7px;
    font-size: 14px;
    padding: 5px;
    margin-bottom: 3px;
    background-color: #777;
    color: #eee;
}

#page-label {
    margin-left: 25px;
    margin-right: 5px;
}

#page {
    margin: 0 5px 0 5px;
}

.entry-batch {
    display: inline-block;
    vertical-align: top;
}
