.entry {
    border-style: solid;
    border-width: 2px;
    display: inline-block;
    border-radius: 5px;
    padding: 0 5px;
    font-size: 14px;
    position: relative;
    top: -4px;
    color: white;
}
.frontier-entry {
    display: inline-block;
}

/*
.views-100 {
    background-color: lighten(#fba7ac, 5%);
}
.views-25 {
    background-color: lighten(#fba7ac, 10%);
}
.views-5 {
    background-color: lighten(#fba7ac, 15%);
}
*/

.modified-bar {
    display: inline-block;
    width: 5px;
    margin: 0 5px;
    height: 23px;
    position: relative;
    top: 2px;
}
.modified-bar-green {
    background-color: darken(mediumseagreen, 10%);
}

.bfast-bar {
    display: inline-block;
    width: 5px;
    margin: 0 5px;
    height: 23px;
    position: relative;
    top: 2px;
}
.bfast-bar-red {
    background-color: darkred;
}

.entry-shell {
    display: block;
    height: 27px;

    &:hover {
        cursor: pointer;
        background-color: #444;
    }
}
.entry-shell-frontier {
    padding: 5px;
    height: 30px;
}
.entry-shell-selected {
    background-color: #274965;

    &:hover {
        background-color: #274965;
    }
}
